<template>
  <div class="whatPKU" v-loading="loading">
    <div class="title">
      <!-- What is PKU-REOD? -->
      {{ whatIsInfo.title }}
    </div>
    <div class="content" v-html="whatIsInfo.content"></div>
    <div class="footer">
      <div class="bottom">
        <span><el-link :href="whatIsInfo.jumpurl" :underline="false" target="_blank">{{ i18nData.learnmore }}</el-link></span
        ><span>></span>
      </div>
      <div class="right">
        <el-image
          style="width: 350px; height: 285px;"
          :src="whatIsInfo.image?baseUrl + whatIsInfo.image:require('./../../public/img/home/produce_img1.png')"
          fit="cover"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <!-- <img src="./../../public/img/home/produce_img1.png" alt="" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "whatPKU",
  props: {
    msg: String
  },
  data() {
    return {
      loading: true,
      whatIsInfo: {
        title: "What is PKU-REOD?",
        content:
          'Prezentowany opis stanowi element sporzdzonej na podstawie analizy krajowych i zagranicznych zasobów cyfrowych bazy wiedzy rejestrujcej internetowe róda informacji z zakresu nauk przyrodniczo-matematycznych. Zosta przygotowany przez pracowników Instytutu Informacji Naukowej i Bibliotekoznawstwa Uniwersytetu Jagiellońskiego w ramach projektu Synat. Tre publikacji stanowi odnonik do opisanego zasobu (ten sam odnonik znajduje si w polu ródo). Ca baz mona przeglda, wybierajc w lewym panelu nawigacyjnym kolekcj tematyczn Baza zasobów cyfrowych z zakresu nauk przyrodniczo-matematycznych".'
      }
    };
  },
  mounted() {
    this.getWhatIs();
  },
  methods: {
    async getWhatIs() {
      // 获取左边横排列表
      let res = await this.getpages("whatis");
      this.whatIsInfo = res.pageInfo;
      this.loading = false;
      console.log("whatIsInfo", this.whatIsInfo);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@mixin bottom() {
  .bottom {
    display: flex;
    align-items: center;
    color: #9b0000;
    span:nth-child(1) {
      font-size: 10px;
      font-weight: bold;
      ::v-deep .el-link.el-link--default{
        color:#9b0000;
      }
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}
.whatPKU {
  padding: 60px 170px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  color: #838383;
  font-size: 9px;
  .title {
    font-size: 36px;
    font-weight: lighter;
  }
  .content {
    width: 100%;
    padding: 20px 0;
    text-align: left;
    line-height: 20px;
  }
  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include bottom;
    .right {
      img {
        width: 350px;
        height: 285px;
      }
    }
  }
}
</style>
