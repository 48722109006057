<template>
  <div class="home">
    <div class="bgimg">
      <el-image
        class="bg-img"
        style="width: 100%; height: 100%;position: absolute;top: 0;left: 0;z-index: 0;"
        :src="require('./../../public/img/home/home_bg.png')"
        fit="cover"
      >
        <div slot="placeholder" class="image-slot">
          加载中<span class="dot">...</span>
        </div>
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
      <!-- <img class="bg-img" src="./../../public/img/home/home_bg.png" alt="" /> -->
      <div class="bgimg-text">
        <div>{{ i18nData.title1 }}</div>
        <div>{{ i18nData.title2 }}</div>
      </div>
      <div class="searchBox">
        <el-input
          :placeholder="i18nData.placehold"
          v-model="search"
          class="input-search"
          @keyup.enter.native="toSearch"
        >
          <el-button
            class="button-search"
            slot="append"
            icon="el-icon-search"
            @click="toSearch"
          ></el-button>
        </el-input>
      </div>
      <div class="tabBox">
        <div
          class="tabitem"
          :class="locale == 'en-US' ? '' : ''"
          v-for="(item, index) in tablist"
          :key="item.name"
          :index="index"
          @click="toDatabase(item.id)"
        >
          <el-image
            style="width: 44px; height: 44px;padding-bottom: 10px;"
            :src="require('./../../public/img/home/' + item.name + '.png')"
            fit="cover"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img
            :src="require('./../../public/img/home/' + item.name + '.png')"
            alt=""
          /> -->
          <span>
            <!-- {{ locale == "en-US" ? item.name : item.cname }} -->
            {{item.name}}
          </span>
        </div>
      </div>
    </div>
    <StatisticsBar :statistical="statistical" v-loading="!statistical.length>0"></StatisticsBar>
    <whatPKU></whatPKU>
    <swiper :banner-list="bannerList"></swiper>
    <div class="blogsBox">
      <div class="title">
        <span>{{ i18nData.blogs }}</span>
        <div class="bar"></div>
      </div>
      <blogsList :archives-list="archivesList"></blogsList>
    </div>
  </div>
</template>

<script>
import StatisticsBar from "./../components/StatisticsBar.vue";
import whatPKU from "./../components/whatPKU.vue";
import swiper from "./../components/swiper.vue";
import blogsList from "./../components/blogsList.vue";
import { search } from "./../common/top.mixin.js";

export default {
  name: "Index",
  mixins: [search],
  components: {
    StatisticsBar,
    whatPKU,
    swiper,
    blogsList
  },
  data() {
    return {
      filterList: [],
      orderList: [],
      archivesList: [],
      channel: {},
      is_show: false,
      has_more: false,
      is_empty: false,
      bannerList: [],
      // 头部数据
      statistical: [],
      tablist: [
        {
          cname: "多环芳烃",
          name: "PAHs",
          id: 10
        },
        {
          cname: "内分泌干扰",
          name: "EDCs",
          id: 9
        },
        {
          cname: "个人护理",
          name: "PPCPs",
          id: 8
        },
        {
          cname: "消毒副产物",
          name: "DBPs",
          id: 26
        },
        {
          cname: "烷基类物质",
          name: "PFAS",
          id: 25
        }
      ]
    };
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getStatistical();
    this.pcSwiper();
    this.getArchives();
  },
  computed: {},
  methods: {
    //跳转database页面
    toDatabase(id) {
      this.$router.push({
        path: "/database",
        query: {
          databaseBar: id
        }
      });
    },
    async getArchives() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: 1,
          channel: -1,
          model: -1
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { filterList, orderList, pageList, channel } = res.data;
        this.filterList = filterList;
        this.orderList = orderList;
        this.channel = channel;
        this.loading = false;
        if (this.is_update) {
          this.archivesList = [];
          this.is_update = false;
        }
        this.is_show = true;
        this.has_more = pageList.current_page < pageList.last_page;
        this.archivesList = [...this.archivesList, ...pageList.data];
        this.is_empty = !this.archivesList.length;
      } catch (err) {
        console.log(err);
      }
    },
    //获取头部请求的数字
    async getStatistical() {
      try {
        let res = await this.$api.https.statistical();
        // console.log("头部请求数字", res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.statistical = res.data;
      } catch (error) {
        console.log(err);
      }
    },
    // 获取首页轮播图图片
    async pcSwiper() {
      try {
        let params = {
          type: "pcSwiper"
        };
        let res = await this.$api.https.getblocklist(params);
        // console.log("头部请求数字", res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        this.bannerList = res.data;
      } catch (error) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #9b0000;
.home {
  background: #ffffff;
  .bgimg {
    width: 960px;
    height: 380px;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
    padding: 80px 0 0 0;
    .bgimg-text {
      color: #fff;
      z-index: 9;
      div:nth-child(1) {
        font-size: 30px;
      }
      div:nth-child(2) {
        font-size: 18px;
        padding: 10px 0 20px;
      }
    }
    .bg-img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .searchBox {
      .input-search {
        width: 487px;
        height: 30px;
        border-radius: 5px;
      }
      .button-search {
        background-color: #fff;
      }
    }
    .tabBox {
      z-index: 9;
      color: #fff;
      font-size: 18px;
      display: flex;
      width: 400px;
      justify-content: space-around;
      padding-top: 24px;
      .tabitem {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 44px;
          height: 44px;
          padding-bottom: 10px;
        }
      }
      .tabitem.zh-CN {
        font-size: 14px;
      }
    }
  }
  .blogsBox {
    padding: 40px 50px;
    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $vue_color;
      font-size: 24px;
      font-weight: bold;
      .bar {
        width: 20px;
        height: 2px;
        background: $vue_color;
        margin-top: 12px;
      }
    }
  }
}
</style>
